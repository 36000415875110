/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-19",
    versionChannel: "nightly",
    buildDate: "2024-06-19T00:05:37.323Z",
    commitHash: "e4a37efddf3d717041eb78576c0acea8f51d57ab",
};
